import { TooltipFormatterCallbackFunction } from 'highcharts';
import { roundForGraph } from '../../../shared/utils/rounding';

export const boxplotTooltipFormatter: TooltipFormatterCallbackFunction = function () {
  const x = this.x;
  const currentData = this.series.data.find((data) => data.x === x);
  const boxplotValues = currentData ? currentData.options : {};
  return `<b>${this.key}</b> (3 s.f.)<br/>
              Maximum: ${roundForGraph(boxplotValues.high)}<br/>
              Upper Quartile: ${roundForGraph(boxplotValues.q3)}<br/>
              Median: ${roundForGraph(boxplotValues.median)}<br/>
              Lower Quartile: ${roundForGraph(boxplotValues.q1)}<br/>
              Minimum: ${roundForGraph(boxplotValues.low)}<br/>`;
};
