import { Injectable } from '@angular/core';
import { JsonReportModel, JsonReportTable, ReportTableType } from './json-report.model';
import { GenericReportModel } from './generic-report.models';
import { IBarChartInfo } from '../../../features/graphs/column-chart/BarChartInfo.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';

@Injectable({
  providedIn: 'root',
})
export class JsonReportService {
  constructor(private documentHttpService: DocumentHttpV2Service) {}

  /**
   * Retrieves a JSON report from the a document and returns it in a generic format for use in the UI.
   *
   * @param {string} documentID entityID of the target document in Nucleus
   * @param {string} sourceBlob Name of the blob that stores the JSON report, must be present on the chosen entity.
   * @returns {Observable<GenericReportModel>}
   */
  getGenericReport(documentID: string, sourceBlob: string): Observable<GenericReportModel> {
    return this.documentHttpService.getDocumentPart(documentID, sourceBlob, 'json').pipe(
      map((report) => report as JsonReportModel),
      map((report) => this.convertJsonReportToGenericReport(report, documentID, sourceBlob)),
    );
  }

  /**
   * Converts report from the format stored in the JSON file into the format used in the UI.
   *
   * TODO Support Heatmaps and other bar chart value types as necessary.
   *
   * @param {JsonReportModel} jsonReport Json report to convert
   * @param {string} documentID Nucleus entity ID of the associated document
   * @param sourceType For JSON reports this will be the name of the blob the report data originates from.
   * @returns {GenericReportModel} UI format report.
   */
  convertJsonReportToGenericReport(
    jsonReport: JsonReportModel,
    documentID: string,
    sourceType: string,
  ): GenericReportModel {
    const barCharts: IBarChartInfo[] = jsonReport.tables
      .filter((table) => table.tableType === ReportTableType.BAR_CHART)
      .map((jsonTable: JsonReportTable) => ({
        title: jsonTable.name,
        xLabel: jsonTable.xLabel,
        yLabel: jsonTable.yLabel,
        data: [
          {
            name: jsonTable.xLabel,
            data: jsonTable.values.map((value) => [`${value.key}`, value.value]),
            type: 'column',
            color: '#03a9f4',
          },
        ],
      }));
    const params = jsonReport.summaryParams || [];
    return {
      name: jsonReport.name,
      description: jsonReport.description,
      source: sourceType,
      documentID: documentID,
      barCharts: barCharts,
      stackedBarCharts: [],
      boxPlots: [],
      heatmaps: [],
      warnings: [],
      params: params.map((param) => ({ label: param.label, value: param.value })),
    };
  }
}
