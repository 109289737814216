import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  Chart,
  ChartOptions,
  PlotOptions,
  SeriesBoxplotOptions,
  TitleOptions,
  TooltipOptions,
  XAxisOptions,
  YAxisOptions,
} from 'highcharts';
import { ChartComponent } from '../chart/chart.component';
import { Subscription } from 'rxjs';
import { ExportableChart } from '../exportable-chart';
import { boxplotTooltipFormatter } from '../tooltips/boxplot-tooltip';

@Component({
  selector: 'bx-graph-boxplot',
  templateUrl: './graph-boxplot.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ChartComponent],
})
export class GraphBoxplotComponent implements OnInit, OnDestroy, OnChanges, ExportableChart {
  @HostBinding('class') readonly hostClass = 'flex-grow-1 flex-shrink-1 d-flex';

  @Input() title: string;
  @Input() xAxisTitle: string;
  @Input() yAxisTitle: string;
  @Input() data: BoxplotData;
  @Input() type: string;

  @Input() set animations(value: boolean) {
    this.chartOptions = {
      ...this.chartOptions,
      animation: value,
    };

    this.plotOptions = {
      ...this.plotOptions,
      series: {
        ...this.plotOptions?.series,
        animation: value,
      },
    };
  }

  @Output() graphLoaded = new EventEmitter<any>();

  @ViewChild(ChartComponent) chart: ChartComponent;

  chartOptions: ChartOptions = {
    type: 'boxplot',
  };
  plotOptions: PlotOptions = {
    series: {
      animation: true,
    },
  };
  titleOptions: TitleOptions = {};
  xAxisOptions: XAxisOptions = {
    type: 'category',
    labels: {
      overflow: 'justify',
    },
  };
  yAxisOptions: YAxisOptions = {};
  seriesOptions: SeriesBoxplotOptions[];
  tooltipOptions: TooltipOptions = {
    formatter: boxplotTooltipFormatter,
  };

  private resize$: Subscription = Subscription.EMPTY;

  constructor() {}

  ngOnInit() {
    this.titleOptions.text = this.title;
    this.xAxisOptions.title = { text: this.xAxisTitle };
    this.yAxisOptions.title = { text: this.yAxisTitle };
    this.seriesOptions = this.data.data;
  }

  ngOnChanges({ title, xAxisTitle, yAxisTitle, data, type }: SimpleChanges) {
    if (title && !title.firstChange) {
      this.titleOptions.text = title.currentValue;
    }

    if (xAxisTitle && !xAxisTitle.firstChange) {
      this.xAxisOptions.title.text = xAxisTitle.currentValue;
    }

    if (yAxisTitle && !yAxisTitle.firstChange) {
      this.yAxisOptions.title.text = yAxisTitle.currentValue;
    }

    if (data && !data.firstChange) {
      this.seriesOptions = data.currentValue.data;
    }
  }

  ngOnDestroy() {
    this.resize$.unsubscribe();
  }

  onChartLoad(chart: Chart) {
    this.graphLoaded.emit(chart.container?.firstElementChild);
  }

  resize() {
    if (this.chart) {
      this.chart.resize();
    }
  }

  downloadImage(documentName?: string) {
    const name = documentName
      ? `${this.titleOptions.text} (${documentName})`
      : this.titleOptions.text;
    this.chart.downloadImage({ filename: name });
  }

  // TODO Decide on and enable other color schemes.
  // setColors(type: string) {
  //   if (type === 'mutation-distribution-plot') {
  //     this.colors = ['#03a9f4'];
  //   } else {
  //     // @see http://colorbrewer2.org/?type=qualitative&scheme=Paired&n=12
  //     this.colors = [
  //       '#1f78b4',
  //       '#33a02c',
  //       '#fb9a99',
  //       '#e31a1c',
  //       '#fdbf6f',
  //       '#ff7f00',
  //       '#a6cee3',
  //       '#b2df8a',
  //       '#cab2d6',
  //       '#6a3d9a',
  //       '#ffff99',
  //       '#b15928',
  //     ];
  //   }
  // }
}

export interface BoxplotData {
  title: string;
  label: string;
  data: SeriesBoxplotOptions[];
}
