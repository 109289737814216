<bx-chart
  [chartOptions]="chartOptions"
  [plotOptions]="plotOptions"
  [title]="titleOptions"
  [xAxis]="xAxisOptions"
  [yAxis]="yAxisOptions"
  [series]="seriesOptions"
  [tooltip]="tooltipOptions"
  (chartLoaded)="onChartLoad($event)"
>
</bx-chart>
