/**
 * This is the model of the json report file (V1).
 */
export interface JsonReportModel {
  name: string;
  description: string;
  tables: JsonReportTable[];
  summaryParams?: SummaryParam[];
}

export interface JsonReportTable {
  name: string;
  description: string;
  xLabel: string;
  yLabel: string;
  tableType: ReportTableType;
  values: ReportTableValue[];
}

export interface ReportTableValue {
  key: string;
  // TODO Support other value types here as applicable.
  value: number;
}

export interface SummaryParam {
  label: string;
  value: string;
}

export enum ReportTableType {
  BAR_CHART = 'BAR_CHART',
}
